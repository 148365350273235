import React, { useEffect, useState } from 'react';
import { ModalInformacion } from './modal-informacion';
import { useSelector, useDispatch } from 'react-redux';
import { selectBrand } from '../../reducers/brandingSlice';
import { setTicketsAdded, setTicketsSelected, stepsState } from '../../reducers/stepsSlice';
import { getTicket } from '../../services/main';
import { constants } from '../constants';
import { valorExento } from './datos-fiscales';

export function IngresaDatos(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  /**
   * Step Handle
   */
  const dispatch = useDispatch();
  const stepsData = useSelector(stepsState);

  /**
   * View Handle
   */
  const facturaType = 'datos';
  const [modal, setModal] = useState(false);

  /**
   * Form inputs
   */
  const [NoComprobante, setNoComprobante] = useState('');
  const [NoTr, setNoTr] = useState('');
  const [precio, setPrecio] = useState('');
  const [tipoFacturar, setTipoFacturar] = useState('')

  /**
   * Boletos
   */
  const [tickets, setTickets] = useState();
  const [ticketsSeleccionados, setTicketsSeleccionados] = useState([])

  /**
   * Agregar ticket
   */
  const [cargandoAgregarTicket, setCargandoAgregarTicket] = useState(false);

  useEffect(() => {
    let mensaje = <div>
      <p style={{ textAlign: "justify" }}>
        De conformidad con lo establecido en el articulo 29 y 29-A de Código Fiscal de la Federación, así como de las reglas de la Resolución Miscelánea Fiscal vigentes para 2022 y su Anexo 20, se informa que Grupo Estrella Blanca, iniciará a utilizar la nueva versión de facturación 4.0 a partir del 12 de Octubre de 2022.
        <br /><br />
        Por lo anterior a nuestros clientes que requieran un comprobante fiscal, será necesario, sin excepción alguna, que mantengan actualizada su información fiscal contenida en la Constancia de Situación Fiscal para proporcionar, entre otros datos, los siguientes:
        <ul style={{ marginLeft: 15, marginTop: 5 }}>
          <li>R.F.C.</li>
          <li>Nombre, denominación o razón social</li>
          <li>Código postal del domicilio fiscal y</li>
          <li>Régimen fiscal</li>
        </ul>
        <br />
        <b>
          Importante:
        </b>
        <br />
        Favor de capturar la Denominación/Razón Social como se señala en la Constancia de Situación Fiscal.
        <br />
        (No incluir Régimen de Capital).
      </p>
    </div>
    props.setMensajes("Aviso Importante", mensaje);
    props.setIsMostrarAceptar(false);
    props.showModalConfirmar(true);
    return () => {
      props.setIsMostrarAceptar(true);
    }
  }, [])


  /**
   * Opens Information modal and set tickets in the state
   */
  const handleModal = () => {
    // props.handleStep("datosFiscales");

    const selected = [];
    let valid = false;
    tickets.forEach(ticket => {
      if (ticket.selected) {
        selected.push(ticket);
        valid = true;
      }
    });
    if (valid) {
      dispatch(setTicketsSelected(selected));
      setModal(!modal)
    } else {
      props.showErrorModal('error', 'Selecciona al menos un boleto para continuar');
    }
  }

  /**
   * Sets the information type and resets the other inputs
   */
  const changeValue = (event) => {
    const target = event.target;
    switch (target.id) {
      case 'NoComprobante':
        setNoComprobante(target.value ? target.value.toUpperCase() : "");
        break;
      case 'NoTr':
        setNoTr(target.value ? target.value.toUpperCase() : "");
        break;
      case 'Precio':
        //Se valida longitud de campo ya que input tipo number no respeta maxLength
        if (target.value.length > 10) target.value = target.value.slice(0, 10);
        setPrecio(target.value);
        break;
      default:
        break;
    }
  }

  /**
   * Compare the tickets arrays so we can see if a ticket is already on the list
   */
  const compareTickets = (ticketsB) => {
    const ticketsEqual = [];
    for (let i = 0; i < tickets.length; i++) {
      const ticketA = tickets[i];
      for (let i = 0; i < ticketsB.length; i++) {
        const ticketB = ticketsB[i];
        if (ticketA.IdTicket === ticketB.IdTicket) {
          ticketsEqual.push(ticketB.Folio);
        }
      }
    }
    return ticketsEqual;
  }

  const compareTicketsTasa = (ticketsB) => {
    const ticket = ticketsB[0] || null;
    if (ticket) {
      // Se filtra para obtener los tickets del mismo RFC 
      const ticketsRFC = tickets.filter(t => t.RFC === ticket.RFC)
      const ticketTasa = ticketsRFC.find(t => (t.TasaIVA !== ticket.TasaIVA));
      if (ticketTasa && ticketsRFC.length > 0) {
        return ticketsRFC[0];
      }
    }
    return null;
  }

  /**
   * Add a ticket to the list
   * it shows an error if the ticket its already on the list
   * we format the date and add the destination to the ticket
   */
  const addTicket = async () => {
    let body = {
      noComprobante: NoComprobante,
      noTr: NoTr,
      precio: precio,
      marca: brand,
      claveTicket: brand === "eb" ? "AUTOBUS" : brand === "envia" ? "GUIA" : ""
    }

    const tipo = facturaType === 'datos' ? 1 : 2;

    //Se validan los campos capturados
    let errorValidacion = '';
    if (!NoComprobante) {
      errorValidacion = "El # Comprobante es requerido";
    } else if (!NoComprobante.match(/^[a-zA-Z0-9-]*$/)) {
      errorValidacion = "El formato del # Comprobante es incorrecto";
    } else if (!NoTr) {
      errorValidacion = "El # Tr es requerido";
    } else if (!NoTr.match(/^[a-zA-Z0-9-]*$/)) {
      errorValidacion = "El formato del # Tr es incorrecto";
    } else if (!precio) {
      errorValidacion = "El importe total es requerido";
    } else if (!precio.match(/^[0-9]+([\.][0-9]{1,2})?$/)) {
      errorValidacion = "El formato del precio es incorrecto";
    }
    if (errorValidacion) {
      props.showErrorModal('error', errorValidacion);
    } else {
      setCargandoAgregarTicket(true);
      const ticketResponse = await getTicket(body, tipo);
      const json = await ticketResponse.json();
      setCargandoAgregarTicket(false);
      if (json.isSuccess) {
        const ticketsData = json.data;
        ticketsData.forEach(ticketData => {
          ticketData.selected = false;
        })
        if (tickets) {
          const ticketsAlreadyAdded = compareTickets(ticketsData);
          if (ticketsAlreadyAdded.length > 0) {
            const ticketsFolios = ticketsAlreadyAdded.join(', ');
            if (ticketsAlreadyAdded.length === 1) {
              props.showErrorModal('error', `El boleto con folio: ${ticketsFolios} ya está añadido`);
            } else {
              props.showErrorModal('error', `Los boletos con folios: ${ticketsFolios} ya están añadidos`);
            }
          } else {
            limpiarInfo();
            setTickets([...tickets, ...ticketsData]);
            props.showErrorModal('info', 'Boleto(s) Añadido(s)');
          }
        } else {
          limpiarInfo();
          setTickets(ticketsData)
          props.showErrorModal('info', 'Boleto(s) Añadido(s)');
        }
      } else {
        props.showErrorModal('error', json.error);
      }
    }
  }
  const limpiarInfo = () => {
    setNoComprobante("");
    setNoTr("");
    setPrecio("");
  }

  /**
   * Handle the checkbox on the ticket
   */
  const selectTicket = (ticket, index) => {
    const clone = JSON.parse(JSON.stringify(tickets));
    clone[index].selected = ticket.selected ? false : true;
    if (!clone[index].selected) {
      document.getElementById('allSelect').checked = false;
    }
    const ticketSelect = [];
    for (let t of clone) {
      if (t.selected) {
        ticketSelect.push(t);
      }
    }
    setTicketsSeleccionados(ticketSelect)
    setTickets(clone);
  }

  const selectTicketAll = (e) => {
    const tickets = [];
    if (e.target.checked) {
      for (let t of stepsData.ticketsAdded) {
        tickets.push({
          ...t, selected: true
        })
      }
    } else {
      for (let t of stepsData.ticketsAdded) {
        tickets.push({
          ...t, selected: false
        })
      }
    }
    setTickets(tickets);
  }

  const handleEliminarTickets = () => {
    const selected = [];
    let valid = false;
    tickets.forEach(ticket => {
      if (ticket.selected) {
        selected.push(ticket);
        valid = true;
      }
    });
    if (valid) {
      const newTickets = [];
      for (let t of tickets) {
        const find = ticketsSeleccionados.find(ts => ts.IdTicket === t.IdTicket);
        if (!find) {
          newTickets.push(t);
        }
      }
      dispatch(setTicketsAdded(newTickets));
      setTickets(newTickets);
      setTicketsSeleccionados([])
    } else {
      props.showErrorModal('error', 'Selecciona al menos un boleto para eliminar');
    }
  }

  /**
   * React Hook
   */
  useEffect(() => {
    if (tickets) {
      dispatch(setTicketsAdded(tickets));
      const ticketsSeleccionados = [];
      // Se valida si están todos seleccionados
      let isAllSelect = true;
      for (let c of tickets) {
        if (!c.selected) {
          isAllSelect = false;
        } else {
          ticketsSeleccionados.push(c);
        }
      }
      if (isAllSelect && tickets.length > 0) {
        document.getElementById('allSelect').checked = true;
      }
      setTicketsSeleccionados(ticketsSeleccionados);
    } else {
      setTickets(stepsData.ticketsAdded);
    }
  }, [tickets])

  useEffect(() => {
    const tipoFacturar = brand === constants.ENVIA_KEY
      ? "boleto"
      : brand === constants.SENDERO_KEY
        ? "boleto" : "boleto"
    setTipoFacturar(tipoFacturar);
  }, [brand])


  const obtenerTituloParrafo = () => {
    let tituloParrafo = <span>Ingresa los siguientes datos para facturar tu {tipoFacturar}. <br /></span>;
    return <p className="disclaimer">{tituloParrafo}</p>
  }
  const obtenerTituloParrafo2 = () => {
    let tituloParrafo = <div
      style={{ textAlign: "justify" }}
    >
      <span>IMPORTANTE:</span>
      <span
        style={{ textAlign: "justify" }}
      >
        &nbsp;A partir de la compra de su boleto, solo tiene el mes en que lo adquirió y hasta 7 días del mes siguiente para poder generar su factura.
        {brand === constants.EB_KEY
          ? <>
            &nbsp;Para poder generar su factura, debera seguir los siguientes pasos:
            <br /><br />
            1. Para facturar el monto de su boleto deberá sumar el Subtotal y el I.V.A.
            (El total de esta suma es lo que deberá de facturar en el enlace de la siguiente plataforma:&nbsp;
            Bienvenido-Portal Facturación Electrónica
            )
            <br /><br />
            Ejemplo del Ejercicio: Subtotal: $603.02 + IVA: $96.48 = $699.50<br />
            Subtotal: $603.02<br />
            IVA: $96.48<br />
            Programa de Asistencia: $10.00<br />
            Sin Carbono: $1.00<br />
            Precio/Fees: $710.50<br />
            <br />
            2. En caso de que haya adquirido el “Programa de asistencia”, este lo deberá facturar aparte con la aseguradora en &nbsp;
            <span
              className="link"
              style={{ textDecoration: "none" }}
              onClick={() => { window.open("https://estrellablanca.com.mx/programa-de-asistencia", '_blank') }}
            >
              www.estrellablanca.com.mx/programa-de-asistencia.
            </span>
            <br /><br />
            3. Si decidió contribuir al programa “Sin Carbono”, le comentamos que los donativos se asignan al proyecto de compensación de CO2. Por lo tanto, es importante que sepa que el pago no podrá ser reembolsado bajo ningún concepto y la aportación no será facturable.
            <br /><br />
            NOTA: El certificado emitido para el pasajero, es una compensación voluntaria y forma parte de un certificado global de Grupo Estrella Blanca, emitido por MéxiCO2.
          </>
          : ""
        }
      </span>
    </div>
    return <p className="disclaimer">{tituloParrafo}</p>
  }
  const handleLimpiarTickets = () => {
    dispatch(setTicketsAdded([]));
    setTickets([]);
  }
  return (
    <div className="datos">
      <div className="content">
        {obtenerTituloParrafo2()}
        {obtenerTituloParrafo()}
        <div className="form">
          <div className="input-wrapper">
            <input
              onChange={(e) => changeValue(e)}
              id="NoComprobante"
              type="text"
              placeholder="# Comprobante"
              disabled={cargandoAgregarTicket}
              maxLength="25"
              value={NoComprobante}
            />
          </div>
          <div className="input-wrapper">
            <input
              onChange={(e) => changeValue(e)}
              id="NoTr"
              type="text"
              placeholder="# TR"
              disabled={cargandoAgregarTicket}
              maxLength="25"
              value={NoTr}
            />
          </div>
          <div className="input-wrapper">
            <input
              onChange={(e) => changeValue(e)}
              id="Precio"
              type="number"
              disabled={cargandoAgregarTicket}
              placeholder="Importe"
              value={precio}
            />
          </div>
        </div>
        <p className="link" onClick={props.showEjemplos}>¿Dónde encuentro mi # de comprobante?</p>
        <div className="action">
          {cargandoAgregarTicket
            ? <><div className="loader-mini">
              <div className="load"></div>
            </div>
              <br />
            </>
            : <button className="primary" onClick={addTicket}>Añadir {tipoFacturar}</button>
          }
        </div>
        <div className="disclaimer">
          <p>Puedes añadir varios {tipoFacturar}s en una sola factura</p>
        </div>
      </div>

      <div className="list">
        <div className="list-wrapper">
          {stepsData.ticketsAdded && stepsData.ticketsAdded.length > 0
            ? <>
              <table width="100%">
                <tr>
                  <td width="5%">
                    <input
                      id="allSelect"
                      type='checkbox'
                      onChange={selectTicketAll}
                    />
                  </td>
                  <td width="20%"><b># Comprobante</b></td>
                  <td width="45%"><b>Empresa/Línea</b></td>
                  <td width="10%"><b>Tasa</b></td>
                  <td width="20%"><b>Importe</b></td>
                </tr>
                {stepsData.ticketsAdded.map((ticket, index) => {
                  let isExento = ticket.TipoFactor?.toLowerCase() === valorExento;
                  return <tr key={ticket.IdTicket}>
                    <td>
                      <input checked={ticket.selected} type="checkbox" onChange={() => selectTicket(ticket, index)} title="Da clic para seleccionar" />
                    </td>
                    <td>
                      <p>{ticket.Folio}</p>
                    </td>
                    <td><p>{ticket.RazonSocial}</p></td>
                    <td><p>{!isExento ? `${(ticket.TasaIVA * 100)}%` : "Exento"}</p></td>
                    <td>
                      <p>${Number(ticket.Total).toFixed(2)}</p>
                    </td>
                  </tr>
                })}
              </table>
            </>
            : <p className="empty">Sin Boletos</p>
          }
        </div>
      </div>
      <div
        className="action"
        style={{
          display: "flex",
          justifyContent: 'space-around',
          marginLeft: "12%",
          width: "75%",
        }}
      >
        <button
          disabled={!stepsData.ticketsAdded || (stepsData.ticketsAdded && stepsData.ticketsAdded.length === 0)}
          onClick={() => handleEliminarTickets()}
          className="ternary-second"
        >
          Eliminar
        </button>
        <button
          disabled={!stepsData.ticketsAdded || (stepsData.ticketsAdded && stepsData.ticketsAdded.length === 0)}
          onClick={() => handleModal()}
          className="ternary-second"
        >
          Siguiente
        </button>
      </div>

      <div className={modal ? 'modal-wrapper active' : 'modal-wrapper'}>
        <ModalInformacion handleStep={props.handleStep} handleModal={handleModal} showErrorModal={props.showErrorModal} />
      </div>
    </div >
  )

}